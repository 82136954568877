import { Dialog, DialogContent } from "@mui/material";
import { usePreventScroll } from "@react-aria/overlays";
import { getKey } from "core/model/utils/strings";
import type { DeepNullableObj, SingerAssessment } from "core/types";
import DatePickerInputField from "ds_legacy/components/DatePickerInputField";
import RadioGroupV2, {
  RadioGroupV2Props,
} from "ds_legacy/components/RadioGroupV2";
import {
  BLACK,
  BORDER_COLOR,
  GREY_100,
  PRIMARY_DARK_COLOR,
  PRIMARY_LIGHT_COLOR,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { border, dp, margin, padding, rem } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_FAMILY,
  FONT_SIZE_10,
  FONT_SIZE_11,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_SEMI_BOLD,
  LINE_HEIGHT_18,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import { kebabCase } from "lodash";
import { CSSProperties, Fragment, useCallback, useMemo } from "react";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
} from "react-forms-state";
import { ObservableValue } from "react-forms-state/src/Context";
import { Observable } from "rxjs";
import styled from "styled-components";
import { useTranslations } from "translations";
import { dateValueDef, validationValueDef } from "../formDefinition";
import {
  AssessmentActions,
  DialogHeader,
  TotalScore,
  calculateScore,
  useGetFormValues,
} from "../shared";

export type SingerAssessmentModalProps = {
  initialValues?: Partial<Omit<SingerAssessment, "score">>;
  isOpen?: boolean;
  isViewMode?: boolean;
  onCancel?: () => void;
  onSubmit?: (values: SingerAssessment) => void;
};

const WRAPPER_PADDING = 53;
const ROW_HEIGHT = 44;
const LEGEND_HEIGHT = 44;
const LEFT_WIDTH = 240;
const SCORE_WIDTH = 70;

const SCORE_BG_COLOR = PRIMARY_DARK_COLOR;
const LEFT_BG_COLOR = GREY_100;
const RIGHT_BG_COLOR = PRIMARY_LIGHT_COLOR;

const defaultValues: DeepNullableObj<Omit<SingerAssessment, "score">> = {
  date: null,
  form: {
    concentration: null,
    domestic_life_household: null,
    dressing_and_undressing: null,
    food: null,
    hygiene: null,
    listening: null,
    orientation: null,
    planning: null,
    reading: null,
    shower: null,
    social_interaction: null,
    stairs: null,
    stool: null,
    talking: null,
    toilet_use: null,
    transfer: null,
    urination: null,
    walking: null,
    wheelchair: null,
    writing: null,
  },
};

const scoreLabelStyle = (isLast: boolean, isPrint: boolean): CSSProperties => ({
  background: isPrint ? undefined : SCORE_BG_COLOR,
  borderRight: isLast ? undefined : border({ color: WHITE, width: 4 }),
  color: isPrint ? BLACK : WHITE,
  fontSize: FONT_SIZE_16,
  fontWeight: FONT_WEIGHT_SEMI_BOLD,
  margin: 0,
  padding: padding(1),
  textAlign: "center",
  whiteSpace: "nowrap",
});

const ellipsisStyles: CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const radioLabelStyle = (
  isSecondLast: boolean,
  isLast: boolean,
  isPrint: boolean,
): CSSProperties => ({
  background: isPrint ? undefined : RIGHT_BG_COLOR,
  borderBottom: isLast ? undefined : border({ color: WHITE, width: 4 }),
  borderRight: isSecondLast ? border({ color: WHITE, width: 4 }) : undefined,
  boxSizing: "border-box",
  fontSize: isPrint ? FONT_SIZE_10 : FONT_SIZE_14,
  fontWeight: FONT_WEIGHT_REGULAR,
  height: rem(55),
  lineHeight: LINE_HEIGHT_18,
  margin: 0,
  padding: padding(1),
  textAlign: "center",
  ...(isPrint ? {} : ellipsisStyles),
});

const getFieldsetStyle = (isPrint: boolean): CSSProperties => ({
  background: isPrint ? undefined : RIGHT_BG_COLOR,
  border: "none",
  margin: 0,
  padding: 0,
  paddingTop: dp(LEGEND_HEIGHT),
  position: "relative",
  width: "inherit",
});

const getFormLabelSx = (
  i: number,
  isPrint: boolean,
): RadioGroupV2Props["formLabelSx"] =>
  ({
    alignItems: "center",
    background: isPrint ? undefined : LEFT_BG_COLOR,
    borderRight: border({ color: BORDER_COLOR }),
    borderTop: i === 0 ? undefined : border({ color: BORDER_COLOR }),
    bottom: 0,
    boxSizing: "border-box",
    display: "flex",
    fontSize: FONT_SIZE_14,
    fontWeight: FONT_WEIGHT_REGULAR,
    left: 0,
    maxWidth: dp(LEFT_WIDTH),
    padding: padding(0, 1, 0, 4),
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
  }) as const;

const getFormControlLabelSx = (
  i: number,
  isPrint: boolean,
): RadioGroupV2Props["formControlLabelSx"] => ({
  alignItems: "center",
  background: isPrint ? undefined : RIGHT_BG_COLOR,
  borderTop: i === 0 ? undefined : border({ color: BORDER_COLOR }),
  boxSizing: "border-box",
  display: "flex",
  flex: 1,
  flexWrap: "nowrap",
  height: "inherit",
  justifyContent: "center",
  margin: 0,
  padding: padding(0, 2),
  whiteSpace: "nowrap",
});

const formControlSx: RadioGroupV2Props["formControlSx"] = {
  boxSizing: "border-box",
  height: dp(ROW_HEIGHT),
  paddingLeft: dp(LEFT_WIDTH),
  position: "relative",
  width: "100%",
  borderRight: border({ color: WHITE, width: 4 }),
};

const StyledRadioWrapper = styled.div`
  border-radius: ${dp(8)};
  box-sizing: border-box;
  overflow: hidden;
  padding: ${padding(WRAPPER_PADDING / 8, 0, 0)};
  position: relative;
  width: 100%;

  & > * + * {
    margin: ${margin(0.5, 0, 0, 0)} !important;
  }
`;

const StyledRadioTopWrapper = styled.div<{ isPrint: boolean }>`
  background: ${({ isPrint }) => (isPrint ? "" : LEFT_BG_COLOR)};
  border-radius: ${dp(8)};
  box-sizing: border-box;
  display: grid;
  left: 0;
  max-width: inherit;
  overflow: hidden;
  position: absolute;
  top: ${dp(12)};
  width: 100%;
  z-index: 10;
`;

const StyledLegend = styled.legend<{ i: number; isPrint: boolean }>`
  align-items: center;
  border-right: ${({ i }) =>
    i === 0
      ? border({ color: BORDER_COLOR })
      : border({ color: WHITE, width: 4 })};
  box-sizing: border-box;
  color: inherit;
  display: flex;
  font-family: ${FONT_FAMILY};
  font-size: ${FONT_SIZE_16};
  font-weight: ${FONT_WEIGHT_BOLD};
  height: ${dp(LEGEND_HEIGHT)};
  padding: ${({ i }) => padding(0, i === 0 ? 2 : 0)};
  position: absolute;
  top: 0;
  width: ${({ i }) =>
    i === 0 ? dp(LEFT_WIDTH) : `calc(100% - ${dp(SCORE_WIDTH)})`};
  z-index: 20;

  & > span {
    align-items: center;
    background: ${({ isPrint }) => (isPrint ? "" : LEFT_BG_COLOR)};
    border-right: ${border({ color: BORDER_COLOR })};
    box-sizing: border-box;
    display: flex;
    height: inherit;
    padding: ${padding(0, 2)};
    width: ${dp(LEFT_WIDTH)};
  }
`;

function addLineBreakBeforeBracket(input: string): JSX.Element {
  // Split the string at the '(' character and rejoin with a <br /> element before the '('
  const parts = input.split("(");
  return (
    <>
      {parts[0]}
      {parts.slice(1).map((part, index) => (
        <Fragment key={index}>
          <br />({part}
        </Fragment>
      ))}
    </>
  );
}

function useSingerAssessmentConfig(
  initialValues: SingerAssessment["form"] | undefined,
) {
  const translations = useTranslations();

  const config: {
    fields: {
      label: string;
      name: keyof SingerAssessment["form"];
      value: number | undefined;
    }[];
    legend: string;
  }[] = useMemo(
    () => [
      {
        legend:
          translations.patient.medicalDiagnosis.singerPatientProfile
            .singerDomainSelfsufficiency,
        fields: [
          {
            name: "food",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .selfsufficiencyEat,
            value: initialValues?.food,
          },
          {
            name: "dressing_and_undressing",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .selfsufficiencyDress,
            value: initialValues?.dressing_and_undressing,
          },
          {
            name: "hygiene",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .selfsufficiencyHygiene,
            value: initialValues?.hygiene,
          },
          {
            name: "shower",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .selfsufficiencyShower,
            value: initialValues?.shower,
          },
          {
            name: "stool",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .selfsufficiencyStool,
            value: initialValues?.stool,
          },
          {
            name: "urination",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .selfsufficiencyUrine,
            value: initialValues?.urination,
          },
          {
            name: "toilet_use",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .selfsufficiencyToilet,
            value: initialValues?.toilet_use,
          },
        ],
      },
      {
        legend:
          translations.patient.medicalDiagnosis.singerPatientProfile
            .singerDomainMobility,
        fields: [
          {
            name: "transfer",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .mobilityTransfer,
            value: initialValues?.transfer,
          },
          {
            name: "wheelchair",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .mobilityWheelchair,
            value: initialValues?.wheelchair,
          },
          {
            name: "walking",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .mobilityWalk,
            value: initialValues?.walking,
          },
          {
            name: "stairs",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .mobilityStairs,
            value: initialValues?.stairs,
          },
        ],
      },
      {
        legend:
          translations.patient.medicalDiagnosis.singerPatientProfile
            .singerDomainCommunication,
        fields: [
          {
            name: "listening",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .communicationListening,
            value: initialValues?.listening,
          },
          {
            name: "talking",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .communicationTalk,
            value: initialValues?.talking,
          },
        ],
      },
      {
        legend:
          translations.patient.medicalDiagnosis.singerPatientProfile
            .singerDomainLearn,
        fields: [
          {
            name: "reading",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .learnRead,
            value: initialValues?.reading,
          },
          {
            name: "writing",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .learnWrite,
            value: initialValues?.writing,
          },
        ],
      },
      {
        legend:
          translations.patient.medicalDiagnosis.singerPatientProfile
            .singerDomainTask,
        fields: [
          {
            name: "orientation",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .taskOrientation,
            value: initialValues?.orientation,
          },
          {
            name: "concentration",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .taskConcentration,
            value: initialValues?.concentration,
          },
          {
            name: "planning",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .taskPlanning,
            value: initialValues?.planning,
          },
        ],
      },
      {
        legend:
          translations.patient.medicalDiagnosis.singerPatientProfile
            .singerDomainSocialInteraction,
        fields: [
          {
            name: "social_interaction",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .socialInteraction,
            value: initialValues?.social_interaction,
          },
        ],
      },
      {
        legend:
          translations.patient.medicalDiagnosis.singerPatientProfile
            .singerDomainDomesticLife,
        fields: [
          {
            name: "domestic_life_household",
            label:
              translations.patient.medicalDiagnosis.singerPatientProfile
                .domesticLifeHousehold,
            value: initialValues?.domestic_life_household,
          },
        ],
      },
    ],
    [initialValues],
  );

  return config;
}

function useGetRadioOptions(): RadioGroupV2Props["options"] {
  const translations = useTranslations();

  return [
    {
      id: 0,
      ariaLabel:
        translations.patient.medicalDiagnosis.singerPatientProfile
          .scoreProfessionalHelpZero,
      value: 0,
    },
    {
      id: 1,
      ariaLabel:
        translations.patient.medicalDiagnosis.singerPatientProfile
          .scoreProfessionalHelpOne,
      value: 1,
    },
    {
      id: 2,
      ariaLabel:
        translations.patient.medicalDiagnosis.singerPatientProfile
          .scoreSupporterTwo,
      value: 2,
    },
    {
      id: 3,
      ariaLabel:
        translations.patient.medicalDiagnosis.singerPatientProfile
          .scoreSupporterThree,
      value: 3,
    },
    {
      id: 4,
      ariaLabel:
        translations.patient.medicalDiagnosis.singerPatientProfile
          .scoreIndependentFour,
      value: 4,
    },

    {
      id: 5,
      ariaLabel:
        translations.patient.medicalDiagnosis.singerPatientProfile
          .scoreIndependentFive,
      value: 5,
    },
  ];
}

export function SingerAssessmentContent({
  initialFormValue,
  isViewMode,
  onChange,
  valueChangeObs,
}: {
  initialFormValue?: SingerAssessment;
  isViewMode?: boolean;
  onChange?: (value: any, statePath: string, validation: any) => void;
  valueChangeObs?: Observable<ObservableValue>;
}) {
  const isPrint = usePrint();
  const translations = useTranslations();
  const config = useSingerAssessmentConfig(initialFormValue?.form);
  const radioOptions = useGetRadioOptions();

  let formValue = useGetFormValues(valueChangeObs);
  if (isViewMode && initialFormValue) {
    formValue = initialFormValue.form;
  }

  return (
    <VerticalLayout width="100%" overflow="visible" gap={padding(2)}>
      <VerticalLayout width="100%" overflow="visible">
        {!isViewMode && (
          <Body
            style={{ margin: 0 }}
            fontSize={FONT_SIZE_14}
            fontWeight={FONT_WEIGHT_MEDIUM}
            lineHeight={LINE_HEIGHT_18}
          >
            {
              translations.patient.medicalDiagnosis.singerPatientProfile
                .subtitle
            }
          </Body>
        )}
        {!isPrint && !isViewMode && <AsteriskExplained />}
      </VerticalLayout>
      <div>
        <DatePickerInputField
          disableFuture
          disabled={isViewMode && !isPrint}
          elementName="date"
          hasCustomValidation
          label={
            translations.patient.medicalDiagnosis.singerPatientProfile
              .creationDate
          }
          onChange={(value) => onChange?.(value as any, "date", true)}
          placeholder={
            translations.patient.medicalDiagnosis.singerPatientProfile
              .assessmentDatePlaceholder
          }
          required={!isPrint && !isViewMode}
          value={isViewMode ? initialFormValue?.date : undefined}
        />
      </div>
      <StyledRadioWrapper>
        <StyledRadioTopWrapper isPrint={isPrint}>
          <div
            style={{
              boxSizing: "border-box",
              display: "grid",
              gridTemplateColumns: `${dp(LEFT_WIDTH)} repeat(3, 2fr) ${dp(
                SCORE_WIDTH,
              )}`,
            }}
          >
            <div></div>
            {[
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreProfessionalHelp,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreSupporter,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreIndependent,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scorePoints,
            ].map((label, i, arr) => (
              <Body
                data-testid={`radio-column-${kebabCase(label)}`}
                as="p"
                margin={margin(0)}
                key={i}
                style={scoreLabelStyle(arr.length - 1 === i, isPrint)}
              >
                {label}
              </Body>
            ))}
          </div>
          <div
            style={{
              boxSizing: "border-box",
              display: "grid",
              gridTemplateColumns: `${dp(LEFT_WIDTH)} repeat(6, 1fr) ${dp(
                SCORE_WIDTH,
              )}`,
            }}
          >
            <div></div>
            {[
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreProfessionalHelpZero,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreProfessionalHelpOne,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreSupporterTwo,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreSupporterThree,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreIndependentFour,
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreIndependentFive,
              "",
            ].map((label, i, arr) => (
              <Body
                data-testid={`radio-label-${i}`}
                as="p"
                margin={margin(0)}
                key={i}
                style={radioLabelStyle(
                  arr.length - 2 === i,
                  arr.length - 1 === i,
                  isPrint,
                )}
              >
                {addLineBreakBeforeBracket(label)}
              </Body>
            ))}
          </div>
        </StyledRadioTopWrapper>
        {config.map(({ fields, legend }, i) => (
          <fieldset key={getKey(legend, i)} style={getFieldsetStyle(isPrint)}>
            <StyledLegend
              i={i}
              isPrint={isPrint}
              data-testid={`radio-section-${legend}`}
            >
              {i === 0 ? legend : <span>{legend}</span>}
            </StyledLegend>
            {fields.map(({ label, name, value }, i) => (
              <div
                key={name}
                style={{
                  display: "grid",
                  gridTemplateColumns: `6fr ${dp(SCORE_WIDTH)}`,
                }}
              >
                <RadioGroupV2
                  elementName={`form.${name}`}
                  formControlLabelSx={getFormControlLabelSx(i, isPrint)}
                  formControlSx={formControlSx}
                  formLabelSx={getFormLabelSx(i, isPrint)}
                  formHelperTextSx={{
                    position: "absolute",
                    left: dp(19),
                    top: dp(24),
                    fontSize: FONT_SIZE_11,
                  }}
                  key={name}
                  label={label}
                  options={radioOptions.map((option) => ({
                    ...option,
                    checked:
                      isViewMode && typeof value === "number"
                        ? option.value === value
                        : undefined,
                    disabled: isViewMode && !isPrint,
                  }))}
                  radioGroupSx={{
                    flexWrap: "nowrap",
                    height: "inherit",
                  }}
                  radioSx={{
                    background: WHITE,
                    padding: 0,
                    "&.Mui-checked": {
                      color: isPrint ? BLACK : undefined,
                    },
                  }}
                  required={!isPrint && !isViewMode}
                  row
                />

                <div style={getFormControlLabelSx(i, isPrint) as CSSProperties}>
                  <Body
                    data-testid={`radio-score-${name}`}
                    as="p"
                    fontSize={FONT_SIZE_16}
                    fontWeight={FONT_WEIGHT_SEMI_BOLD}
                    style={{ margin: 0 }}
                  >
                    {(formValue as SingerAssessment["form"])?.[name]}
                  </Body>
                </div>
              </div>
            ))}
          </fieldset>
        ))}
      </StyledRadioWrapper>
      <HorizontalLayout justify="flex-end">
        <TotalScore score={calculateScore(formValue)} />
      </HorizontalLayout>
    </VerticalLayout>
  );
}

export function SingerAssessmentModal({
  initialValues = {},
  isOpen,
  onCancel,
  onSubmit,
}: SingerAssessmentModalProps) {
  usePreventScroll({ isDisabled: !isOpen });
  const translations = useTranslations();
  const { isTablet } = useMedia();

  const handleSubmit = useCallback(
    (values: Omit<SingerAssessment, "score">) => {
      onSubmit?.({ ...values, score: calculateScore(values.form) });
    },
    [onCancel],
  );

  return (
    <Dialog
      fullScreen={isTablet}
      maxWidth={false}
      onClose={onCancel}
      open={!!isOpen}
      PaperProps={{
        "aria-modal": "true",
        sx: { maxWidth: dp(1050), width: "100%" },
      }}
    >
      <DialogHeader
        title={translations.patient.medicalDiagnosis.singerPatientProfile.title}
        onCancel={onCancel}
      />
      <SimpleFormRenderProps
        asHtmlForm
        onSubmit={handleSubmit}
        formStyle={{
          boxSizing: "border-box",
          display: "unset",
          padding: padding(0, 2, 2, 0),
        }}
        formInputValue={
          initialValues.form && initialValues.date
            ? initialValues
            : defaultValues
        }
        modelDefinition={convertModelDefinition({
          ...dateValueDef("date"),
          ...validationValueDef("form.concentration"),
          ...validationValueDef("form.domestic_life_household"),
          ...validationValueDef("form.dressing_and_undressing"),
          ...validationValueDef("form.food"),
          ...validationValueDef("form.hygiene"),
          ...validationValueDef("form.listening"),
          ...validationValueDef("form.orientation"),
          ...validationValueDef("form.planning"),
          ...validationValueDef("form.reading"),
          ...validationValueDef("form.shower"),
          ...validationValueDef("form.social_interaction"),
          ...validationValueDef("form.stairs"),
          ...validationValueDef("form.stool"),
          ...validationValueDef("form.talking"),
          ...validationValueDef("form.toilet_use"),
          ...validationValueDef("form.transfer"),
          ...validationValueDef("form.urination"),
          ...validationValueDef("form.walking"),
          ...validationValueDef("form.wheelchair"),
          ...validationValueDef("form.writing"),
        })}
      >
        {({ onChange, submit, valueChangeObs }) => {
          return (
            <>
              <DialogContent sx={{ overflowY: "hidden" }}>
                <SingerAssessmentContent
                  onChange={onChange}
                  valueChangeObs={valueChangeObs}
                />
              </DialogContent>
              <AssessmentActions
                defaultValues={defaultValues}
                onCancel={onCancel}
                onChange={onChange}
                submit={submit}
              />
            </>
          );
        }}
      </SimpleFormRenderProps>
    </Dialog>
  );
}
