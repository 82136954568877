import { LockIcon, WarningIcon } from "ds/icons";
import Tooltip from "ds_legacy/components/Tooltip";
import { ERROR_COLOR, ICON_DARK } from "ds_legacy/materials/colors";
import { FONT_SIZE_16 } from "ds_legacy/materials/typography";
import { useState } from "react";
import { useTranslations } from "translations";

export function EncryptedTooltip({
  activateEncryption,
  isFile = false,
}: {
  activateEncryption: boolean;
  isFile?: boolean;
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const translations = useTranslations();

  if (!activateEncryption)
    return (
      <Tooltip
        title={translations.messenger.notEncryptedHint}
        placement="right-start"
      >
        <WarningIcon
          size={FONT_SIZE_16}
          style={{ fontSize: FONT_SIZE_16, color: ERROR_COLOR }}
        />
      </Tooltip>
    );

  return (
    <div
      role="tooltip"
      tabIndex={0}
      onFocus={() => setTooltipOpen(true)}
      onBlur={() => setTooltipOpen(false)}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      aria-label={
        isFile
          ? translations.messenger.fileTransferEncrypted
          : translations.auctionResponse.sendEncryptedMessageHint
      }
    >
      <Tooltip
        title={
          isFile
            ? translations.messenger.fileTransferEncrypted
            : translations.auctionResponse.sendEncryptedMessageHint
        }
        placement="top-start"
        open={tooltipOpen}
      >
        <LockIcon
          size={FONT_SIZE_16}
          style={{ fontSize: FONT_SIZE_16, color: ICON_DARK }}
        />
      </Tooltip>
    </div>
  );
}

export default EncryptedTooltip;
