import { useCompleteProductTour } from "apollo/hooks/mutations";
import { useGetProductTour } from "apollo/hooks/queries";
import { isCI } from "core/model/utils/featureFlags";
import { IntroOptions, IntroStep, TourElement } from "core/types";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useLayoutEffect, useRef } from "react";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import "./style.css";
import { CAREPROVIDER_DASHBOARD_PAGE } from "./tours/CareproviderDashboardTour/dashboard";
import { RECEIVER_SETTINGS } from "./tours/ReceiverSettingsTour/tourWithoutImages";
import { REVERSE_SEARCH_DASHBOARD_PAGE } from "./tours/ReverseSearchTour/dashboard";
import { REVERSE_SEARCH_PAGE_EXISTING } from "./tours/ReverseSearchTour/existing";
import { REVERSE_SEARCH_PAGE } from "./tours/ReverseSearchTour/noneStarted";
import { SEARCH_MERGE_AUCTION_NOT_STARTED } from "./tours/SearchMergeTour/auctionNotStartedTour";
import { SEARCH_MERGE_AUCTION_STARTED } from "./tours/SearchMergeTour/auctionStartedTour";
import { ACCESS_TO_PATIENT_DATE } from "./tours/SharePatientData";
import { TRANSITIONAL_CARE } from "./tours/TransitionalCareTour/tour";
import { FILES_AVAILABLE_TO_SHARE, FILES_PAGE } from "./tours/filesTour";
import { PARALLEL_SEARCH } from "./tours/parallelSearchTour/tour";

export const PRODUCT_TOURS = {
  [ACCESS_TO_PATIENT_DATE.key]: ACCESS_TO_PATIENT_DATE,
  [FILES_AVAILABLE_TO_SHARE.key]: FILES_AVAILABLE_TO_SHARE,
  [FILES_PAGE.key]: FILES_PAGE,
  [PARALLEL_SEARCH.key]: PARALLEL_SEARCH,
  [REVERSE_SEARCH_DASHBOARD_PAGE.key]: REVERSE_SEARCH_DASHBOARD_PAGE,
  [REVERSE_SEARCH_PAGE_EXISTING.key]: REVERSE_SEARCH_PAGE_EXISTING,
  [REVERSE_SEARCH_PAGE.key]: REVERSE_SEARCH_PAGE,
  [SEARCH_MERGE_AUCTION_NOT_STARTED.key]: SEARCH_MERGE_AUCTION_NOT_STARTED,
  [SEARCH_MERGE_AUCTION_STARTED.key]: SEARCH_MERGE_AUCTION_STARTED,
  [RECEIVER_SETTINGS.key]: RECEIVER_SETTINGS,
  [CAREPROVIDER_DASHBOARD_PAGE.key]: CAREPROVIDER_DASHBOARD_PAGE,
  [TRANSITIONAL_CARE.key]: TRANSITIONAL_CARE,
} as const;

type ProductTourStepConfig<stepKey> = {
  intro: (t: Translations) => IntroStep["intro"];
  key: stepKey;
  position?: IntroStep["position"];
  scrollTo?: IntroStep["scrollTo"];
  step: IntroStep["step"];
  title: (t: Translations) => IntroStep["title"];
};

export type ProductTourKey = keyof typeof PRODUCT_TOURS;

type TourStep<TourKey extends ProductTourKey> =
  keyof (typeof PRODUCT_TOURS)[TourKey]["steps"];

type StepConfigs<TourKey extends ProductTourKey> = {
  [stepKey in TourStep<TourKey>]: ProductTourStepConfig<stepKey>;
};

export type ProductTour<TourKey extends ProductTourKey> = {
  disablePageScroll?: boolean;
  doneLabel?: (t: Translations) => string;
  exitOnOverlayClick?: boolean;
  key: TourKey;
  nextLabel?: (t: Translations) => string;
  prevLabel?: (t: Translations) => string;
  scrollToElement?: boolean;
  steps: StepConfigs<TourKey>;
  tooltipClass?: string;
};

export function tourAttributes<TourKey extends ProductTourKey>({
  stepKey,
  tourKey,
}: {
  stepKey: TourStep<TourKey>;
  tourKey: TourKey;
}): TourElement {
  const tourSteps = PRODUCT_TOURS[tourKey]?.steps;
  const stepConfig = tourSteps?.[
    stepKey as keyof typeof tourSteps
  ] as ProductTourStepConfig<typeof stepKey>;
  const stepNumber = stepConfig.step;
  if (!stepNumber) {
    console.error("Error calculating tour step attributes");
    return {};
  }

  return {
    "data-tour-id": `${tourKey}-step-${stepKey as string}`,
    "data-tour-step": stepNumber,
  };
}

export function TourAttributeWrapper<TourKey extends ProductTourKey>({
  children,
  stepKey,
  tourKey,
}: {
  children: React.ReactNode;
  stepKey: TourStep<TourKey>;
  tourKey: TourKey;
}) {
  return (
    <div
      {...tourAttributes({
        tourKey,
        stepKey,
      })}
    >
      {children}
    </div>
  );
}

function useGetTourOptions<TourKey extends ProductTourKey>({
  tour,
}: {
  tour: ProductTour<TourKey>;
}) {
  const translations = useTranslations();
  return (): Partial<IntroOptions> & { disablePageScroll?: boolean } => {
    const stepsOptions: Partial<IntroStep>[] = Object.keys(tour.steps).map(
      (stepKey): Partial<IntroStep> => {
        const step = stepKey as TourStep<TourKey>;
        const stepConfig = tour.steps[step];
        if (!stepConfig)
          throw new Error(
            `no config found for step ${stepKey} in tour steps ${tour.steps}`,
          );
        return {
          title: stepConfig.title(translations),
          intro: stepConfig.intro(translations),
          element: document.querySelector(
            `[data-tour-id=${`${tour.key}-step-${stepKey}`}]`,
          ) as HTMLElement,
          position: stepConfig.position,
          scrollTo: stepConfig.scrollTo,
        };
      },
    );
    return {
      steps: stepsOptions,
      prevLabel:
        tour.prevLabel?.(translations) ?? translations.tours.files.backButton,
      nextLabel:
        tour.nextLabel?.(translations) ?? translations.tours.files.nextButton,
      doneLabel:
        tour.doneLabel?.(translations) ?? translations.tours.files.doneButton,
      disableInteraction: true,
      exitOnOverlayClick: tour.exitOnOverlayClick ?? true,
      tooltipClass: tour.tooltipClass,
      disablePageScroll: tour.disablePageScroll,
      scrollToElement:
        tour.scrollToElement !== undefined ? tour.scrollToElement : true,
    };
  };
}

export default function useProductTour<TourKey extends ProductTourKey>({
  onCompleted,
  skip,
  tour,
}: {
  onCompleted?: () => void;
  skip?: boolean;
  tour: ProductTour<TourKey>;
}) {
  const stepRef = useRef<HTMLElement>();
  const skippedRef = useRef<boolean>(false);
  const [, data] = useGetProductTour({ name: tour.key, skip });
  const [completeTour] = useCompleteProductTour(data?.product_tour_id);
  const isCompleted = data ? !!data.completed : undefined;
  const getTourOptions = useGetTourOptions({ tour });
  const tourOptions = getTourOptions();
  const [blockScroll, allowScroll] = useScrollBlock();

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (isCompleted === false && !isCI)
        introJs()
          .setOptions(tourOptions)
          .onbeforechange(() => {
            if (tourOptions.disablePageScroll) blockScroll();
            return true;
          })
          .onchange((element) => {
            stepRef.current = element;
          })
          .onskip(() => {
            skippedRef.current = true;
            if (tourOptions.disablePageScroll) allowScroll();
          })
          .onexit(async () => {
            try {
              const step = stepRef.current?.getAttribute("data-tour-step");
              const completionRate =
                Number(step) / Object.keys(tour.steps).length;
              await completeTour({
                completion_rate: completionRate,
                step_id: Number(step),
              });
              if (tourOptions.disablePageScroll) allowScroll();
            } catch (err) {
              console.log(`error completing tour: ${tour.key} - ${err}`);
            } finally {
              if (!skippedRef.current) onCompleted?.();
            }
          })
          .start();
      // some tours can start before the page has fully rendered
      // the tour can then not find the DOM element to link the step
    }, 500);

    return () => clearTimeout(timeout);
  }, [isCompleted]);

  return tour;
}
