import type { History } from "history";
import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { useTranslations } from "translations";

export function useWarnBeforeNavigate({
  onConfirm,
  skip,
  skipPaths = [],
}: {
  onConfirm?: () => Promise<void> | void;
  skip?: boolean;
  skipPaths?: RegExp[];
}) {
  const translations = useTranslations();
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    if (skip) {
      return;
    }

    const unblock = navigator.block(async (transition) => {
      const enhancedTransition = {
        ...transition,
        retry() {
          unblock();
          transition.retry();
        },
      };
      const nextPath = transition.location.pathname;

      if (skipPaths.some((regex) => regex.test(nextPath))) {
        enhancedTransition.retry();
        return;
      }

      if (window.confirm(translations.patient.warningLeavingAssessment)) {
        if (onConfirm) {
          await onConfirm();
        }

        enhancedTransition.retry();
      }
    });

    return () => {
      unblock();
    };
  }, [skip, skipPaths, navigator, onConfirm, translations]);
}
