import { isFutureDay } from "core/model/utils/dates";
import { valueDef } from "react-forms-state";

/**
 * Creates a form value definition that contains required validator
 *
 * @param fieldName form element name
 * @param errorMessage optional error message
 */
export function validationValueDef(fieldName: string, errorMessage?: string) {
  return valueDef(fieldName, {
    validate(value, { translations }) {
      return value == null
        ? {
            customMessage:
              errorMessage ??
              translations.patient.medicalDiagnosis.singerPatientProfile
                .inputValidation,
          }
        : true;
    },
  });
}

/**
 * Creates custom form definition.
 * Defines a convert out to convert the DOM's input checkbox string values from DOM to numbers
 * @param fieldName form element name
 */
export function convertToNumberValueDef(fieldName: string) {
  return valueDef(fieldName, {
    convertOut(value) {
      return typeof value === "string" ? +value : value;
    },
  });
}

export function dateValueDef(fieldName: string) {
  return valueDef(fieldName, {
    validate(date, { translations }) {
      if (!date || isFutureDay(date)) {
        return {
          customMessage:
            translations.patient.medicalDiagnosis.singerPatientProfile
              .creationDateError,
        };
      }
      return true;
    },
  });
}
