import {
  CARESEEKER_TYPE_EMERGENCY,
  CARESEEKER_TYPE_HOSPITAL,
  CARESEEKER_TYPE_PROVIDER_SEARCH,
  LIVING_SITUATION_OTHER,
  RADIUS_IN_KM_15,
  RADIUS_IN_KM_25,
  RADIUS_IN_KM_30,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_MOBILE_CARE,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
} from "core/consts";
import { descriptiveWhitelist } from "core/model/auctions";
import {
  PatientWhitelist,
  PatientWhitelistDefinition,
  SearchType,
} from "core/types";
import {
  ConversionModel,
  convertOutAssertPath,
  valueDef,
  whitelistEncryptedDef,
  whitelistGroupDef,
  whitelistValueDef,
} from "react-forms-state";
import {
  ageDef,
  communicationDef,
  financingSectionDef,
  getHeightModel,
  getWeightModel,
  locationDef,
  payersDef,
} from "../../../ecosystems/PatientAssessment/SharedModelDefinitions";

const displayRadius: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_REHABILITATION,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
  {
    country: "FR",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [SOLUTION_SHORT_TERM_STATIC_CARE, SOLUTION_STATIC_CARE],
  },
];

export const shortTermCareWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [SOLUTION_SHORT_TERM_STATIC_CARE, SOLUTION_STATIC_CARE],
  },
];

export const healthInsuranceWhitelist: PatientWhitelistDefinition = [
  {
    country: "FR",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
];

export const insuranceWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_REHABILITATION,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_MEDICAL_SUPPLIES,
  },
];

export const socialHelpRecipientBoolWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [
      SOLUTION_MOBILE_CARE,
      SOLUTION_SHORT_TERM_STATIC_CARE,
      SOLUTION_STATIC_CARE,
    ],
  },
];
export const reliefServiceBoolWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
  },
];

export const reimbursment_for_help_at_homeWhitelist: PatientWhitelistDefinition =
  [
    {
      country: "DE",
      search_type: SEARCH_TYPE_CARE,
      solutions: [SOLUTION_HELP_AT_HOME, SOLUTION_MOBILE_CARE],
    },
  ];

export const reimbursment_securedWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [SOLUTION_MOBILE_CARE],
  },
];

export const carelevelWhitelist: PatientWhitelistDefinition = [
  { country: "DE" },
];

export const grantsWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_HOSPITAL,
  },
  {
    country: "FR",
    sender_type: CARESEEKER_TYPE_EMERGENCY,
  },
];

export const medicalCardWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_HOSPITAL,
  },
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_PROVIDER_SEARCH,
  },
  {
    country: "FR",
    sender_type: CARESEEKER_TYPE_EMERGENCY,
  },
];

export const getDefinition = ({
  country,
  searchType,
  whitelist,
  withEncryption,
}: {
  country: string;
  searchType: SearchType | null;
  whitelist: PatientWhitelist["info"] | undefined;
  withEncryption: boolean;
}): ConversionModel => ({
  ...valueDef("id"),
  profile: {
    ...locationDef("search_location", { whitelist, fieldRequired: true }),
  },
  ...whitelistValueDef("radius_in_meter", {
    whitelist,
    out_name: "radius_in_km",
    convertIn: (value, props) => {
      if (!value) {
        if (props && descriptiveWhitelist(displayRadius)(props)) {
          switch (props.formInputValue?.search_type) {
            case SEARCH_TYPE_REHABILITATION:
              return RADIUS_IN_KM_30;
            case SEARCH_TYPE_HOSPITAL:
              if (country === "FR") return RADIUS_IN_KM_15;
              else return RADIUS_IN_KM_25;
            default:
              return RADIUS_IN_KM_25;
          }
        }
        return null;
      }
      return value / 1000;
    },
    convertOut: (value, props) =>
      value && props && descriptiveWhitelist(displayRadius)(props)
        ? value * 1000
        : undefined,
  }),
  ...whitelistValueDef("country_wide_search", { whitelist }),
  ...valueDef("patient.id"),
  ...payersDef({ whitelist }),
  patient: {
    profile: {
      ...whitelistEncryptedDef("first_name", { whitelist }),
      ...whitelistEncryptedDef("last_name", { whitelist }),
      ...getWeightModel({ withEncryption, whitelist }),
      ...getHeightModel({ withEncryption, whitelist }),
      ...ageDef(withEncryption, whitelist),
      ...whitelistValueDef("gender", {
        whitelist,
        fieldRequired: true,
      }),
      ...whitelistValueDef("living_situation", {
        whitelist,
      }),
      ...whitelistValueDef("living_situation_other", {
        defaultValue: "",
        convertOut: convertOutAssertPath(
          "living_situation",
          "",
          LIVING_SITUATION_OTHER,
        ),
        whitelist,
      }),
      ...whitelistValueDef("lift_available_state", { whitelist }),
      ...whitelistValueDef("lift_available_description", { whitelist }),
      ...whitelistValueDef("barrier_free", { whitelist }),
      ...whitelistValueDef("barrier_free_description", { whitelist }),
      ...whitelistValueDef("unsafe_current_domestic_situation_state", {
        whitelist,
      }),
      ...whitelistValueDef("unsafe_current_domestic_situation", { whitelist }),
      communication: communicationDef({ whitelist }),
      ...whitelistGroupDef("preferences", {
        whitelist,
        ...whitelistValueDef("specialist_doctor", {
          whitelist,
        }),
        ...whitelistValueDef("room_type", {
          whitelist,
        }),
        ...whitelistValueDef("accompanying_person", {
          whitelist,
        }),
        ...whitelistValueDef("barrier_free_room", {
          whitelist,
        }),
      }),
      ...financingSectionDef({ whitelist, searchType }),
      ...whitelistGroupDef("general_practitioner", {
        whitelist,
        ...whitelistValueDef("name", {
          out_name: "general_practitioner_name",
          whitelist,
        }),
        ...whitelistValueDef("contact_details", {
          out_name: "general_practitioner_contact_details",
          whitelist,
        }),
      }),
    },
  },
});
