import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import { SelectProps } from "@mui/material/Select";
import { CSSProperties } from "@mui/styles";
import { getKey } from "core/model/utils/strings";
import { KEYBOARD_FOCUS_OUTLINE, TEXT_DARK_PRIMARY } from "ds_legacy/materials/colors";
import { border, dp } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14 } from "ds_legacy/materials/typography";
import { useEffect, useState } from "react";
import {
  FieldValidation,
  FormElementRenderProps,
  isValid,
} from "react-forms-state";
import { useTranslations } from "translations";
import { getHelperText } from "../Validation";

export type SelectOption = {
  id: number | string;
  label: number | string;
  value: number | string;
};

export type SelectInputProps<
  Option extends SelectOption = SelectOption,
  Multiple extends boolean | undefined = undefined,
  Nullable extends boolean | undefined = undefined,
> = Pick<
  SelectProps,
  "disabled" | "id" | "label" | "placeholder" | "required" | "size" | "variant"
> & {
  ariaLabelledBy?: string;
  elementName?: string;
  formHelperTextSx?: SxProps<Theme>;
  fullWidth?: boolean;
  inputSx?: SxProps<Theme>;
  multiple?: Multiple;
  nullable?: Multiple extends true ? never : Nullable;
  onChange: (
    value: Multiple extends true
      ? Option["value"][]
      : Nullable extends true
      ? Option["value"] | null
      : Option["value"],
    statePath?: string,
  ) => void;
  options: Option[];
  style?: CSSProperties;
  validation?: FieldValidation;
  value: Multiple extends true ? Option["value"][] : Option["value"] | null;
};

export type ConnectedSelectInputProps<
  Option extends SelectOption = SelectOption,
  Multiple extends boolean | undefined = undefined,
  Nullable extends boolean | undefined = undefined,
> = Omit<
  SelectInputProps<Option, Multiple, Nullable>,
  "onChange" | "validation" | "value"
> & {
  flatModel?: boolean;
  sideMutation?: (newValue: any, mutateElement: AnyFunction) => void;
};

export function optionToSelectOption<
  Option extends SelectOption = SelectOption,
>(option: unknown): Option {
  return { value: option, id: option, label: option } as unknown as Option;
}

export function optionsToSelectOptions<
  Option extends SelectOption = SelectOption,
>(options: unknown[]): Option[] {
  return options.map(optionToSelectOption) as unknown as Option[];
}

const labelStyle: CSSProperties = {
  fontSize: FONT_SIZE_14,
  color: TEXT_DARK_PRIMARY,
};

const menuItemStyle = (theme: Theme): CSSProperties => ({
  ...labelStyle,
  ":hover": {
    outline: `${dp(1)} solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.common.white,
  },
});

export function SelectInput<
  Option extends SelectOption = SelectOption,
  Multiple extends boolean | undefined = undefined,
  Nullable extends boolean | undefined = undefined,
>({
  ariaLabelledBy,
  disabled,
  elementName,
  formHelperTextSx,
  fullWidth,
  id,
  inputSx,
  label,
  multiple,
  nullable,
  onChange,
  options,
  placeholder,
  required,
  size,
  style,
  validation,
  value,
  variant = "standard",
}: SelectInputProps<Option, Multiple, Nullable>) {
  const errorTextId = `${elementName}_error_text_id`;
  const translations = useTranslations();
  const [localValue, setValue] = useState<Option["value"] | Option["value"][]>(
    multiple ? [] : "",
  );
  const defaultId = id || elementName;
  const labelId = ariaLabelledBy
    ? ariaLabelledBy
    : label
    ? `${defaultId}-label`
    : undefined;

  const handleChange = (event: SelectChangeEvent<typeof localValue>) => {
    const { value } = event.target;

    setValue(value);

    onChange(
      (multiple
        ? value
        : `${value}`?.length
        ? value
        : null) as Multiple extends true
        ? string[]
        : Nullable extends true
        ? string | null
        : string,
    );
  };

  useEffect(() => {
    setValue(
      (value != null ? value : multiple ? [] : "") as
        | Option["value"]
        | Option["value"][],
    );
  }, [value]);

  const hasError = !isValid(validation);

  return (
    <FormControl
      disabled={disabled}
      error={hasError}
      fullWidth
      required={required}
      sx={(theme) => ({
        ...labelStyle,
        ...style,
        "& .MuiInputBase-root.Mui-focused": {
          outlineOffset: dp(-2),
          outline: border({ color: theme.palette.primary.main, width: 2 }),
        },
        "& .MuiSelect-select": {
          ...labelStyle,
        },
        "& .MuiFormLabel-root": labelStyle,
      })}
      size={size}
      variant={variant}
    >
      {label && (
        <InputLabel id={labelId} data-testid={labelId}>
          {label}
        </InputLabel>
      )}
      <Select
        aria-labelledby={hasError ? errorTextId : undefined}
        data-testid={defaultId}
        displayEmpty={nullable}
        fullWidth={fullWidth}
        id={defaultId}
        labelId={labelId}
        multiple={multiple}
        name={elementName}
        onChange={handleChange}
        renderValue={(selected) => {
          if (multiple) {
            const selectedOptions = options.filter((option) =>
              (selected as string[]).includes(option.value as string),
            );
            return (
              <div style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selectedOptions?.map(({ label, value }) => (
                  <Chip key={value} label={label} />
                ))}
              </div>
            );
          }
          const selectedOption = options.find(
            (option) => option.value === selected,
          );

          if (!selectedOption) return placeholder ?? "";

          return selectedOption.label;
        }}
        sx={{
          ...inputSx,
          "&.Mui-focused": {
            outline: `${dp(2)} solid ${KEYBOARD_FOCUS_OUTLINE}`,
            outlineOffset: dp(-2),
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        value={localValue}
      >
        {nullable && !multiple && (
          <MenuItem
            data-testid="option-none"
            divider
            key={getKey("none")}
            sx={menuItemStyle}
            value=""
          >
            <em>{translations.general.noPreference}</em>
          </MenuItem>
        )}
        {options.map(({ label, value }) => {
          return (
            <MenuItem
              data-testid={`option-${value}`}
              divider
              key={value}
              sx={menuItemStyle}
              value={value}
            >
              {multiple ? (
                <>
                  <Checkbox
                    checked={
                      (localValue as Option["value"][]).indexOf(value) > -1
                    }
                    size="small"
                    sx={labelStyle}
                  />
                  <span style={labelStyle as any}>{label}</span>
                </>
              ) : (
                label
              )}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText
        id={errorTextId}
        error={!isValid(validation)}
        sx={formHelperTextSx}
      >
        {getHelperText({
          hasCustomValidation: true,
          translations,
          validation,
        })}
      </FormHelperText>
    </FormControl>
  );
}

export default function ConnectedSelectInput<
  Option extends SelectOption = SelectOption,
  Multiple extends boolean | undefined = undefined,
  Nullable extends boolean | undefined = undefined,
>({
  disabled,
  elementName,
  formHelperTextSx,
  id,
  inputSx,
  label,
  multiple,
  nullable,
  options,
  placeholder,
  required,
  sideMutation,
  size,
  variant = "standard",
  ...props
}: Omit<
  SelectInputProps<Option, Multiple, Nullable>,
  "onChange" | "validation" | "value"
> & {
  flatModel?: boolean;
  sideMutation?: (newValue: any, mutateElement: AnyFunction) => void;
}) {
  return (
    <FormElementRenderProps
      elementName={elementName}
      sideMutation={sideMutation}
      {...props}
    >
      {({ onChange, validation, value }) => (
        <SelectInput<Option, Multiple, Nullable>
          disabled={disabled}
          elementName={elementName}
          formHelperTextSx={formHelperTextSx}
          id={id}
          size={size}
          inputSx={inputSx}
          label={label}
          multiple={multiple}
          nullable={nullable}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          required={required}
          validation={validation}
          value={value}
          variant={variant}
        />
      )}
    </FormElementRenderProps>
  );
}
